import { Component, OnInit } from '@angular/core';
declare var $: any; //jquery usage
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'portfolio';
  config: any;
  fullpage_api: any;
  constructor() {

    // for more details on config options please visit fullPage.js docs
    this.config = {

      // fullpage options


      // fullpage callbacks
      afterResize: () => {
        console.log("After resize");
      },
      afterLoad: (origin, destination, direction) => {
        console.log(origin.index);
      }
    };
  }

  getRef(fullPageRef) {
    this.fullpage_api = fullPageRef;
  }

  ngOnInit(){
    //jquery
   

  }

}
